@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

.app{
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.slick-slide {
 padding: 5px;
}

.slick-prev:before, .slick-next:before{
  color: #555 !important;
}
